import { io } from 'socket.io-client';
import type { SingleSocket } from './type';

export const getIoClient = (...[url, options]: Parameters<typeof io>): SingleSocket => {
  const ret = io(url ?? '', {
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 10000,
    ...options,
    auth: {
      clientSide: true,
      serverSide: false,
      ...options?.auth,
    }
  });

  return ret;
}
