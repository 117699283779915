import type { Game, User } from "@prisma/client";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import invariant from 'tiny-invariant';
import _ from "~/helpers/utils";
import type { SingleSocket } from "~/socket/type";
import type { StateSetter } from "~/types/react";

const isDev = _?.isDev() ?? false;

export type UserIdentity = {
  userID: string
  username: string
  slug?: string
  playerID?: number
}
type MinimalGameForSocket = Pick<Game, "gameMasterId" | "slug">

export type SocketIoProps = {
  socket: SingleSocket | undefined
  setGame: StateSetter<MinimalGameForSocket | undefined>
  connected: boolean
  bindIdentity: (slug: string, playerID?: number) => void
  hasSessionId: boolean
}
// Définition du context SocketIo
export const SocketIo = createContext<SocketIoProps>(undefined as any);
SocketIo.displayName = 'SocketIo';
export const SocketIoConsumer = SocketIo.Consumer;

type SocketIoProviderProps = PropsWithChildren<{
  user: Pick<User, "id" | "displayName"> | null
  socket: SingleSocket | undefined
}>
type TT = NonNullable<SingleSocket>
type On = Parameters<TT['on']>[1]
const mounted = {} as Record<Parameters<TT['on']>[0], Array<On>>;
const mountListener = (...[socket, ev, listener]: [SingleSocket | undefined, ...Parameters<TT['on']>]) => {
  if (socket) {
    socket.on(ev, listener)
    if (!mounted[ev]) {
      mounted[ev] = []
    }
    mounted[ev].push(listener)
  }
}

const unMountListener = (socket?: SingleSocket) => {
  Object.keys(mounted).forEach(k => {
    const kName = k as keyof typeof mounted;
    mounted[kName].forEach(listener => {
      socket?.off(kName, listener)
    })
  })
}


export const SocketIoProvider = ({
  user,
  socket,
  children }: SocketIoProviderProps) => {

  const [game, setGame] = useState<MinimalGameForSocket>();
  const [connected, setConnected] = useState(false);

  const { slug } = { ...game };

  const {
    id = '',
    displayName = '',
  } = { ...user };

  const hasSessionId = socket && socket.auth && 'sessionID' in socket.auth && typeof socket.auth.sessionID === 'string' && socket.auth.sessionID.length > 0 ? true : false;

  const innerBindIdentity = useCallback((slug?: string, playerID?: number) => {
    if (socket && id !== '') {
      hasSessionId && socket.emit('bind identity', {
        userID: id,
        username: displayName,
        slug,
        playerID,
        // @ts-expect-error
        sessionID: socket.auth?.sessionID,
      })
    }
  }, [socket, id, displayName, hasSessionId])

  useEffect(() => {

    mountListener(socket, 'connect', () => {
      isDev && console.log('on client connect'); //FIXME: REMOVE CLOG
      innerBindIdentity();
      setConnected(true);
    })

    mountListener(socket, 'connect_error', () => {
      isDev && console.log('on client connect_error'); //FIXME: REMOVE CLOG
      setConnected(false);
    })

    mountListener(socket, 'disconnect', () => {
      isDev && console.log('on client disconnect'); //FIXME: REMOVE CLOG
      setConnected(false);
    })

    // socket?.onAny((...args) => {
    //   isDev && console.log('client onAny', ...args)
    // })

    return () => {
      unMountListener(socket)
      socket?.offAny()
    }
  }, [socket, innerBindIdentity])

  useEffect(() => {
    innerBindIdentity(slug)
    // const to = setInterval(() => {
    //   innerBindIdentity(slug)
    // }, 30 * 1000);
    // return () => {
    //   clearInterval(to)
    // }
  }, [innerBindIdentity, slug])

  const bindIdentity = (slug: string, playerID?: number) => {
    innerBindIdentity(slug, playerID);
  }

  return <SocketIo.Provider
    value={{
      socket,
      setGame,
      connected,
      bindIdentity,
      hasSessionId,
    }}
  >
    {children}
  </SocketIo.Provider>;
};

export const useSocketIoContext = () => {
  const cntxt = useContext(SocketIo);
  invariant(
    !!cntxt,
    `SocketIo context is undefined, please verify you are calling useSocketIo() as child of a <SocketIoProvider> component.`
  );
  return cntxt;
}
