import bf from 'get-browser-fingerprint';
import { useEffect } from 'react';
type ClientFingerprintProps = {

}
export default function ClientFingerprint({
  ...props
}: ClientFingerprintProps) {

  useEffect(() => {
    const fingerprint = bf();
    //const fingerprint = '...';
    document.dispatchEvent(new CustomEvent('cf', {
      detail: fingerprint
    }));
    document.cookie = `cf=${fingerprint}; path=/`;
  }, []);
  return undefined;
}