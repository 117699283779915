import clsx from "clsx";
import { useState } from "react";
import _ from "~/helpers/utils";
import { useOnKeyboardEvent } from "~/hook/useOnKeyboardEvent";

const Outline = () => {

  useOnKeyboardEvent(({ key, altKey, shiftKey }) => {

    if (shiftKey && altKey && key.toUpperCase() === 'D') {
      document.documentElement.classList.toggle('_outline');
      return false;
    }
  })

  return undefined;
}

type DebugScenarioProps = {

}
export default function DebugSizes({
  ...props
}: DebugScenarioProps) {
  const [open, setOpen] = useState(false);



  if (!_.isDev()) {
    return null;
  }

  return <div
    onDoubleClick={_ => setOpen(p => !p)}
    className={clsx(open && "open", `cursor-pointer fixed top-[50vh] z-[1000] 
    [--ow:.5rem]
    [--w:6rem]
    max-sm:[--w:6rem]
    max-md:[--w:7rem]
    max-lg:[--w:8rem]
    lg:[--w:9rem]
    w-[var(--w)] -ml-[var(--w)] 
    left-[var(--ow)]  p-1 pr-4 bg-lime-600 z-100 rounded-sm 
    [&.open]:-ml-[var(--ow)]
    hover:-ml-[var(--ow)] 
    transition-all 
    text-center
    after:h-[40%] after:top-[30%] after:absolute after:right-1 after:border-r-2 after:border-r-lime-800 after:content-[' ']
    [&.open]:after:opacity-0
    hover:after:opacity-0
    [&.open>h1]:text-blue-800
    [&.open>h1]:text-right
    select-none
  `)}>
    <Outline />
    <h1 className=" text-sm font-bold">Sizes</h1>
    <div className="flex flex-col gap-2 items-end">
      <div>
        <div className="hidden max-sm:inline">&lt;max-sm</div>
        <div className="hidden sm:max-md:inline">max-md</div>
        <div className="hidden md:max-lg:inline">max-lg</div>
        <div className="hidden lg:max-xl:inline">max-xl</div>
        <div className="hidden xl:max-2xl:inline">max-2xl</div>
        <div className="hidden 2xl:inline">&gt;max-2xl</div>
      </div>
      <div className="space-x-1 text-xs flex flex-wrap">
        <div className="inline">&lt;sm</div>
        <div className="hidden sm:inline">sm</div>
        <div className="hidden md:inline">md</div>
        <div className="hidden lg:inline">lg</div>
        <div className="hidden xl:inline">xl</div>
        <div className="hidden 2xl:inline">2xl</div>
      </div>
    </div>
  </div>

}