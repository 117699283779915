import { cssBundleHref } from "@remix-run/css-bundle";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import logoFT from "~/../public/images/fibre.jpg";
import type { ActionFunction, AppLoadContext, HeadersFunction, LinksFunction, LoaderFunctionArgs, MetaFunction, ShouldRevalidateFunction } from "~/remix";
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError,
  useRouteLoaderData } from
"~/remix";


import stylesheet from "./tailwind.css";

import { library } from '@fortawesome/fontawesome-svg-core';
import { useCallback, useEffect, useState, type PropsWithChildren } from "react";
import invariant from "tiny-invariant";
import * as icons from '~/icons.def';
// import { RELNO } from '~/relno';
import fontAwesomeSheetUrl from '@fortawesome/fontawesome-svg-core/styles.css';
import { getIoClient } from "~/socket/socket.client";
import ClientFingerprint from './components/Layout/ClientFingerprint';
import DebugSizes from './components/Layout/DebugSizes';
import type { ThemeDef } from "./components/Theme/ThemeSelect";
import { SocketIoProvider } from "./contexts/SocketIoContext";

import { getEnv } from "./helpers/env.server";
import { combineHeaders } from "./helpers/misc";
import { getTheme, setTheme } from "./helpers/theme.server";
import { combineServerTimings, makeTimings, time } from "./helpers/timings.server";
import { getRandomUUID } from "./helpers/utils.server";
import { useSessionStorage, useSessionStorageObject } from "./hook/useBrowserStorage";
import { useThemeChange } from "./hook/useThemeChange";
import { getGameSettings } from "./models/setting.server";
import { getUser } from "./session.server";
import stylesStylesheetUrl from "./styles/styles.css";
import { useGetAppBranch, useGetAppHash, useGetAppVersion } from "./utils";
import cn from "./helpers/styles";
import _ from "./helpers/utils";

export const defaultTheme = "light";
export const defaultDarkTheme = "night";

export const castDarkThemeSpecial = (theme: string) => {
  if (theme === 'night') {

    // return 'halloween';
  }return theme;
};

export const isDarkTheme = (theme: string) => {
  return ['night', 'halloween'].includes(theme);
};

export const links: LinksFunction = () => {
  return [
  { rel: "stylesheet", href: stylesheet },
  ...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
  // { rel: "stylesheet", href: customStylesheetUrl },
  { rel: "stylesheet", href: stylesStylesheetUrl },
  { rel: "stylesheet", href: fontAwesomeSheetUrl }];

};

export const meta: MetaFunction = () => {
  return [
  {
    charset: "utf-8"
  },
  {
    title: `MOSARpg`
  }
  // {
  //   viewport: "width=device-width,initial-scale=1",
  // },
  ];
};

export const headers: HeadersFunction = ({ loaderHeaders, parentHeaders, actionHeaders }) => {
  const headers = {
    'Server-Timing': combineServerTimings(loaderHeaders, parentHeaders, actionHeaders)
  };
  return headers;
};

export function getClientFingerprintFromCookie(request: Request) {
  const [, cf] = request.headers.get("Cookie")?.match(/cf=(\d*)\s?/) ?? ['', ''];
  return cf;
}

export const useRootLoaderData = () => {
  const ret = useRouteLoaderData<typeof loader>('root');
  if (ret === undefined) {
    throw Error("Unable to read root loader");
  }
  return ret;
};

export const getServerFingerprint = (request: Request, context: AppLoadContext) => request.headers.get('x-sfp') ?? context.serverUUID ?? '';

export const action: ActionFunction = async ({ request }) => {
  const formData = Object.fromEntries(await request.formData());
  const { slug, intent, theme } = formData;

  if (intent === 'setThemeCookie') {
    if (typeof theme === 'string' && theme.length > 0) {

      const sSlug = slug ? String(slug) : undefined;

      const responseInit = {
        headers: { 'set-cookie': setTheme(theme, sSlug) }
      };
      return json({ success: true, theme }, responseInit);
    }
    return false;
  }
  return null;
};



export async function loader({ request, context }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const serverUUID = getServerFingerprint(request, context);

  [
  'DATABASE_URL',
  'SESSION_SECRET',
  'SESSION_NAME',
  'TWITCH_CLIENT_ID',
  'TWITCH_CLIENT_SECRET',
  'TWITCH_REDIRECT_URI',
  'APP_BUILD_DATE',
  'APP_GIT_BRANCH',
  'APP_GIT_HASH',
  'APP_WS_URL',
  'SITE_NICKNAME',
  'SITE_BASENAME',
  'VERSION',
  'DONATION_BTN'].
  forEach((key) => {
    try {
      invariant(process.env[key] !== undefined, `Missing ${key} in .env, must be defined`);
    } catch (e) {
      if (e && typeof e === 'object' && 'message' in e) {
        console.error(e.message);
      }
      throw e;
    }
  });

  const [cookieTheme, serverSettings, user] = await Promise.all([
  getTheme(request),
  time(getGameSettings(0), {
    timings,
    type: 'getGameSettings',
    desc: 'getGameSettings in root'
  }),
  time(getUser(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  })]
  );

  const buildInfos = {
    date: process.env.APP_BUILD_DATE,
    branch: process.env.APP_GIT_BRANCH,
    hash: process.env.APP_GIT_HASH,
    version: process.env.VERSION
  };

  let theme: string | undefined = castDarkThemeSpecial(cookieTheme ?? '');
  // let themeGame: string | undefined = getThemeFromCookie(request);
  const availableThemes = ((serverSettings.get('themes') ?? []) as ThemeDef[]);
  let socketAvailable = serverSettings.get('socket') === 1;

  if (process.env.NO_SOCKET === 'on') {
    console.log('NO_SOCKET provided'); //FIXME: REMOVE CLOG
    socketAvailable = false;
  }

  if (!availableThemes.find((at) => at.theme === theme)) {
    theme = undefined;
  }

  if (theme) {
    theme = castDarkThemeSpecial(theme);
  }

  const parseSocketUrl = (url: string) => {
    if (url && (url.startsWith('#') || url.startsWith('!'))) {
      return null;
    }
    return url;
  };

  return json({
    theme: theme ?? defaultTheme,
    availableThemes,
    version: process.env.VERSION,
    buildInfos,
    ENV: getEnv(),
    env: process.env.NODE_ENV,
    APP_WS_URL: parseSocketUrl(process.env.APP_WS_URL),
    SITE_NICKNAME: process.env.SITE_NICKNAME,
    SITE_BASENAME: process.env.SITE_BASENAME,
    DONATION_BTN: process.env.DONATION_BTN,
    user,
    cf: getClientFingerprintFromCookie(request),
    sf: serverUUID,
    socketSessionID: getRandomUUID(),
    socketAvailable,
    isDev: _.isDev()
  },
  {
    headers: combineHeaders(
      { 'Server-Timing': timings.toString() }
    )
  }
  );
}

export const shouldRevalidate: ShouldRevalidateFunction = ({ defaultShouldRevalidate,
  formAction,
  formMethod,
  nextUrl,
  ...props
}) => {
  let rv = false;
  switch (true) {
    case formAction && formMethod === 'PATCH':
    case formAction && formAction.startsWith('/login') && formMethod === 'POST':
    case formAction && formAction.startsWith('/?index') && formMethod === 'POST':
    case formAction && formAction.startsWith('/join') && formMethod === 'POST':
    case formAction && formAction.startsWith('/logout') && formMethod === 'POST':
      // case (nextUrl && nextUrl.pathname === '/dashboard' && formMethod === 'GET'):
      rv = true;
      break;

  }
  return rv;
};

type MininimalHTMLProps = PropsWithChildren<{
  title?: string;
}>;

export function Footer() {

  const hash = useGetAppHash();
  const branch = useGetAppBranch();
  const { versionHTML } = useGetAppVersion();
  const { DONATION_BTN } = useRootLoaderData();

  return <div className="bg-base-200 sticky top-[100vh] text-center">
    <footer className="mx-auto py-4 text-sm justify-center">
      <div className="tooltip" data-tip={`${hash} - ${branch}`}>
        <span dangerouslySetInnerHTML={{ __html: versionHTML ?? '' }}></span> - <span>{hash}</span>
      </div>
      &nbsp;&bull;&nbsp;
      <div className="tooltip" data-tip={`Faire un don`}>
        <a href={DONATION_BTN} target="paypal">Soutenir le projet</a>
      </div>
      <div className="text-xs [&_a]:underline grid grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 w-[38rem] max-sm:w-full mx-auto
      [&_>div>span]:mx-[.2rem]
      justify-items-center
      ">



        <div className='sm:justify-self-end'>
          Fait avec <span>💙</span> par <a href="//twitter.com/TonyTruand_" target="_blank" rel="noreferrer">TonyTruand</a>
          <span className="hidden sm:inline-block">&nbsp;&bull;</span>
        </div>
        <div className="sm:justify-self-start">
          d'après une idée de <a href="//twitter.com/FibreTigre" target="_blank" rel="noreferrer">FibreTigre</a>&nbsp;<img className="w-5 rounded-full inline" src={logoFT} alt="logo FibreTigre" />
          <span className="hidden md:inline-block">&nbsp;</span>
        </div>
        {/* <div className="max-md:justify-self-center justify-self-start max-md:col-span-2 max-sm:col-span-1">
           inspiré par <a href="//twitter.com/minotau_re" target="_blank" rel="noreferrer">Minotaure</a>&nbsp;<img className="w-5 inline" src="https://raw.githubusercontent.com/fibreville/minotaure/main/src/img/minotaure_logo.svg" alt="logo minotaure" />
          </div> */}
      </div>
    </footer></div>;
}


export function MininimalHTML({
  title = 'Oh no!',
  children
}: MininimalHTMLProps) {
  return (
    <html lang="fr" className="">
      <head>
        <title>{title}</title>
        <Meta />
        <Links />
      </head>
      <body className="h-full bg-base-100">
        <Scripts />
        <LiveReload />
        <div className="flex h-full justify-center items-center">
          <div className="bg-base-200 p-16 rounded-lg text-center">
            {children}
          </div>
        </div>
        <Footer />
      </body>
    </html>);

}



export function ErrorBoundary() {
  const error = useRouteError();
  const [{ env, user }] = useSessionStorageObject('loader', {
    release: '???',
    env: 'no-dev',
    user: '???'
  });

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {

    const Content = () => {
      switch (error.status) {
        case 404:
          return <div>
            <h1 className="text-6xl font-bold"><span>40</span><div className="inline-block rotate-180">4</div></h1>
            <div>
              Cette page n'existe pas
            </div>
            <Link to="/dashboard" className="btn mt-4">Retour à la case départ</Link>
          </div>;
        // return 
        default:
          return <>{error.status}<br />
            {error.statusText}
          </>;
      }
    };


    return <MininimalHTML>
      <div>
        <Content />
      </div>
    </MininimalHTML>;
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage = "Unknown error";
  if (error && typeof error === 'object' && 'message' in error) {
    errorMessage = (error.message as string);
  }

  if (env === 'development') {
    console.error('User => ', user ?? '???');
  }
  captureRemixErrorBoundaryError(error);
  return <MininimalHTML>
    🚀 Houston we have a problem
    <div>
      <Link to="/dashboard" className="btn mt-4">Retour à la case départ</Link>
      <pre className={`${env !== 'development' ? "hidden" : 'mt-4'}`}>
        {errorMessage}
      </pre>
    </div>
  </MininimalHTML>;

}

function App() {

  const loaderProps = useLoaderData<typeof loader>();
  const { theme, user, APP_WS_URL, socketSessionID, socketAvailable, ENV, isDev } = loaderProps;

  library.add(icons);

  const [socket, setSocket] = useState<ReturnType<typeof getIoClient>>();
  const [sessionID, setSessionID] = useSessionStorage('sessionID', socketSessionID);

  const hasSessId = sessionID.length > 0;

  const mountSocket = socketAvailable && user !== null;

  const auth = useCallback(() => ({
    sessionID: sessionID,
    userID: user?.id,
    username: user?.displayName
  }), [user, sessionID]);

  useEffect(() => {
    if (!socket && mountSocket && APP_WS_URL !== null) {
      const s = getIoClient(APP_WS_URL, {
        autoConnect: false,
        auth: auth()
      });
      setSocket(s);
    }
  }, [APP_WS_URL, socket, auth, mountSocket]);

  useEffect(() => {
    if (socket && !socket?.connected) {
      socket.connect();
      return () => {
        socket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APP_WS_URL, auth, socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on('session', ({ session: { sessionID } }) => {
      setSessionID(sessionID);
      socket.auth = {
        ...socket.auth,
        sessionID
      };
    });
    return () => {
      socket.off('session');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (!socket || !user) return;
    if (hasSessId) {
      socket.emit('bind identity', {
        userID: user.id,
        username: user.displayName
      });
    }
  }, [socket, user, hasSessId]);

  useThemeChange(theme);

  return <html lang="fr" className={cn(isDev && 'isDev')}>
    <head>
      {<style>{`.svg-inline--fa { height: 1em; }`}</style>}
      <Meta />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1" />

      <Links />
    </head>
    <body className="antialiased min-h-screen flex flex-col justify-center">
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(ENV)}`
        }} />

      <DebugSizes />
      <ClientFingerprint />
      <SocketIoProvider user={user} socket={socket}>
        <Outlet />
      </SocketIoProvider>
      <Footer />
      <ScrollRestoration />
      <Scripts />
      <LiveReload />
    </body>
  </html>;

}

export default withSentry(App);