import { useEffect } from "react";
import { useRootLoaderData } from "~/root";

export const useThemeChange = (theme?: string) => {

  const { theme: rootTheme } = useRootLoaderData();
  const activeTheme = theme ?? rootTheme;

  useEffect(() => {
    if (activeTheme && typeof activeTheme === 'string' && activeTheme.length > 0) {
      document.documentElement.setAttribute('data-theme', activeTheme);
    }
  }, [activeTheme]);
}
